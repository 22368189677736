<template>
 <div class="my-box" v-loading="loading">
  <!-- 功能区域 -->
  <el-row>
   <el-card shadow="always">
    <el-form label-width="50px" class="topS">
     <el-row :gutter="10">
      <el-col :span="3">
       <el-button @click="addBtn" v-if="showadd!=-1">新增</el-button>
       <el-button @click="batchesDelete" v-if="showdelete!=-1">批量删除</el-button>
      </el-col>
      <el-col :span="4">
       <el-form-item label="品牌">
        <el-select v-model="seekData.brandId" @change="selectOne" placeholder="请选择" clearable filterable>
         <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="4">
       <el-form-item label="门店">
        <el-select v-model="seekData.hotelId" @change="selectTwo" placeholder="请选择" clearable filterable>
         <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="4">
       <el-form-item label="建筑">
        <el-select v-model="seekData.buildingId" placeholder="请选择" clearable filterable>
         <el-option v-for="(item,index) in buildingSelectData" :key="index" :label="item.buildingName" :value="item.id"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="4">
       <el-form-item label="楼层">
        <el-input placeholder="请输入内容" v-model="seekData.floorName" clearable class="my-input"></el-input>
       </el-form-item>
      </el-col>
      <el-col :span="5" class="reset-button">
       <el-button type="primary" @click="handleSearch">查询</el-button>
       <el-button @click="reset">重置</el-button>

      </el-col>
     </el-row>
    </el-form>

   </el-card>
  </el-row>
  <!-- 内容区域 -->
  <el-row>
   <el-card shadow="always">
    <div class="my-header-table">
     <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" :height="height" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="brandName" label="品牌"></el-table-column>
      <el-table-column prop="hotelName" label="门店"></el-table-column>
      <el-table-column prop="buildingName" label="建筑"></el-table-column>
      <el-table-column prop="floorName" label="楼层" sortable></el-table-column>
      <el-table-column prop="id" label="楼层ID" sortable></el-table-column>
      <!-- <el-table-column prop="description" label="描述"></el-table-column> -->

      <!-- 操作按钮列 -->
      <el-table-column label="操作" width="150">
       <template slot-scope="scope">
        <!-- 编辑按钮 -->
        <el-button v-if="showupdate!=-1" type="primary" size="mini"  @click="handleEdit(scope.row)">修改</el-button>
        <!-- 删除按钮 -->
        <el-button v-if="showdelete!=-1" size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
       </template>
      </el-table-column>
     </el-table>
     <el-pagination background layout="prev, pager, next, sizes, total, jumper" :page-sizes="[10, 20, 50, 100]" :page-size="seekData.pageSize" :current-page="seekData.pageNum" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange"></el-pagination>
    </div>
   </el-card>
  </el-row>
  <!-- 编辑的弹框 -->
  <el-dialog title="编辑楼层" :visible.sync="dialogFormVisible2" class="astrict">
   <el-form :model="editData" :ref="editData" :rules="myrules" :label-width="formLabelWidth">
    <el-form-item label="名称" prop="floorName" >
     <el-input v-model.trim="editData.floorName" placeholder maxlength="10"></el-input>
    
    </el-form-item>
      <el-form-item label="楼层类型" prop="floorType">
     <el-select v-model="editData.floorType"  clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in floorTypes" :key="index" :label="item.lable" :value="item.value"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="梯控顺序" prop="elevatorSort">
     <el-input v-model="editData.elevatorSort" placeholder="请输入梯控顺序"></el-input>
    </el-form-item>
    <el-form-item label="描述">
     <el-input v-model="editData.description" type="textarea" :rows="5" placeholder="请输入内容"></el-input>
    </el-form-item>
   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible2 = false">取 消</el-button>
    <el-button type="primary" @click="confirmEditD(editData)" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>

  <!-- 新增的弹框 -->
  <el-dialog title="新增楼层" :visible.sync="dialogFormVisible" class="astrict">
   <el-form :model="addform" ref="addform" :rules="myrules" :label-width="formLabelWidth">

    <el-row style="margin-bottom: 0;">
     <el-col :span="12">
      <el-form-item label="楼层" prop="beg">

       <el-input-number style="width: 100%;" v-model="addform.beg" :precision="0" controls-position="right" label="描述文字" placeholder="输入的数字代表要添加多少楼层" ></el-input-number>
      </el-form-item>

     </el-col>
     <el-col :span="12">
      <el-form-item label="至" label-width="40px" prop="end">
       <el-input-number style="width: 100%;" v-model="addform.end"  :precision="0" controls-position="right" label="描述文字" placeholder="输入的数字代表要添加多少楼层"></el-input-number>
      </el-form-item>
     </el-col>
    </el-row>
    <el-form-item label="楼层类型" prop="floorType">
     <el-select v-model="addform.floorType"  clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in floorTypes" :key="index" :label="item.lable" :value="item.value"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="梯控顺序" prop="elevatorSorts">
     <el-input v-model="addform.elevatorSorts" placeholder="如：1,2,3,4,5  英文逗号分隔"></el-input>
    </el-form-item>
    <el-form-item label="品牌" prop="brandId">
     <el-select v-model="addform.brandId" @change="selectOne" clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="门店" prop="hotelId">
     <el-select v-model="addform.hotelId" @change="selectTwo" clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="建筑" prop="buildingId" clearable filterable>
     <el-select v-model="addform.buildingId" placeholder="请选择">
      <el-option v-for="(item,index) in buildingSelectData" :key="index" :label="item.buildingName" :value="item.id"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="描述">
     <el-input v-model="addform.description" type="textarea" :rows="5" placeholder="请输入内容"></el-input>
    </el-form-item>
   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="abrogateAdd">取 消</el-button>
    <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import {
 getFloorlList,
 addFloor,
 getHotelSelect,
 getBuildingSelect,
 delFloor,
 getBrandSelect,
 editFloor,
} from "@/api";
export default {
 // name:"lc",
 data() {
  /* 楼层名自定义校验规则 */
  let VerifyFloorName = (rule, value, callback) => {
   if (value === "" || !value) {
    callback(new Error("楼层名不能为空"));
   } else {
    let nameReg = /^[-\u4e00-\u9fa50-9]+$/;
    // /^(?![^a-zA-Z]+$)(?!\D+$).{6,10}$/
    if (nameReg.test(value)) {
     callback();
    } else {
     callback(new Error("不能包含 - 和英文字母"));
    }
   }
  };
  let VerifyBeg = (rule, value, callback) => {
   if (!value) {
    callback(new Error("楼层不能为0"));
   } else {
    callback();
   }
  };
  return {
   height: null,
   multipleSelection: [],
   loading: false,
   // 建筑列表数据
   tableData: null,
   dialogFormVisible2: false,
   dialogFormVisible: false,
   dialogVisible: false,
   formLabelWidth: "100px",
   // 查询的数据
   total: null,
   seekData: {
    brandId: null,
    hotelId: null,
    buildingId: null,
    floorName: null,
    pageSize: 10,
    pageNum: 1,
   },
   floorTypes: [
    {
     lable: "客房区域",
     value: 0,
    },
    {
     lable: "公共区域",
     value: 1,
    },
   ],
   hotelId: null,
   brandId: null,
   // 新增
   addform: {
    hotelId: null,
    brandId: null,
    beg: 1,
    end: 1,
    floorType:null,
    elevatorSorts:null,
    description: null, //建筑描述
    buildingId: null, //建筑ID
   },

   brandSelectData: null,
   hotelSelectData: null,
   buildingSelectData: null,
   selectedOptions: [],
   // 编辑
   editData: {
    floorName: null, //楼层名称
    floorType: null,
    elevatorSort: null,
    buildingId: null,
    description: null, //描述
    id: null, //楼层ID
   },
   // validator: VerifyFloorName
   myrules: {
    floorName: [
     { required: true, validator: VerifyFloorName, trigger: "blur" },
    ],
    beg: [
     { required: true, validator: VerifyBeg, trigger: ["blur", "change"] },
    ],
    end: [
     { required: true, validator: VerifyBeg, trigger: ["blur", "change"] },
    ],
    floorType: [{ required: true, message: "请选择", trigger: "change" }],
    elevatorSorts: [
     { required: true, message: "请按要求输入梯控序号", trigger: "blur" },
    ],
     elevatorSort: [
     { required: true, message: "请按要求输入梯控序号", trigger: "blur" },
    ],
    brandId: [{ required: true, message: "请选择", trigger: "change" }],
    hotelId: [{ required: true, message: "请选择", trigger: "change" }],
    buildingId: [{ required: true, message: "请选择", trigger: "change" }],
   },
   userJurisdiction: null,
   defaultProps: {
    children: "children",
    label: "title",
   },
  };
 },
 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 240;
 },
 computed: {
  // 权限
  showadd: function () {
   return this.userJurisdiction.indexOf("floor:add");
  },
  showdelete: function () {
   return this.userJurisdiction.indexOf("floor:delete");
  },
  showupdate: function () {
   return this.userJurisdiction.indexOf("floor:update");
  },
 },
 created() {
  this.userJurisdiction = this.$store.state.roleMenu;
  // 调用初始化表格数据方法
  this.getList();
  this.initialize();
 },
 methods: {
  
  // 初始化表格数据
  getList() {
   this.loading = true;
   getFloorlList(this.seekData).then((res) => {
    // console.log(res)
    if (res.status === 200) {
     this.tableData = res.data.data.rows;
     this.total = res.data.data.total;
     this.loading = false;
    }
   });
  },
  /* 初始化下拉框 */
  // 获取品牌下拉框
  initialize() {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
     // console.log(res)
    }
   });
  },
  // 获取门店下拉框
  selectOne(id) {
   // console.log(id)
   getHotelSelect(id).then((res) => {
    // console.log(res)
    if (res.data.data) {
     this.addform.hotelId = null;
     this.addform.buildingId = null;
     this.hotelSelectData = res.data.data;
    } else {
     this.$message({
      message: "该品牌下没有门店",
      type: "warning",
     });
    }
   });
  },
  // 获取建筑下拉框
  selectTwo(id) {
   getBuildingSelect(id).then((res) => {
    this.seekData.buildingId = null;
    if (res.data.data.length > 0) {
     this.addform.buildingId = null;
     this.buildingSelectData = res.data.data;
    } else {
     this.$message({
      message: "该门店下没有建筑",
      type: "warning",
     });
    }
   });
  },

  addBtn() {
   this.dialogFormVisible = true;
  },
  

  // 确定按钮
  confirmAdd(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     if (this.addform.beg > this.addform.end) {
      this.$message({
       type: "error",
       message: "请正确输入起止楼层",
      });
      return;
     }
     let data=this.getResertData()
     console.log(data);
     this.addFloorReq(data)
    } else {
     return false;
    }
   });
  },
  getResertData(){
    let data=JSON.parse(JSON.stringify(this.addform))
     delete data.brandId;
     delete data.hotelId;
     return data
  },
  addFloorReq(data){
     this.loading = true;
    addFloor(data)
      .then((res) => {
       console.log(res);
       this.loading = false;
       if (res.data.code == "000000") {
         this.$refs.addform.resetFields()
        this.$message.success(res.data.message);
          this.dialogFormVisible = false;
        this.getList();
      
       } else {
        this.$message.error(res.data.message);
       }
      
      })
      .catch((err) => {
       console.log(err);
      });
  },
 
  // 取消按钮
  abrogateAdd() {
   this.dialogFormVisible = false;
  },

  // 楼层删除
  handleDelete(row) {
   // console.log(row)
   this.$confirm("此操作将永久删除该楼层, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     // 点击确定执行的操作
     this.deleteReq(row.id)
    })
    .catch(() => {
     this.$message({
      type: "info",
      message: "已取消删除",
     });
    });
  },

  batchesDelete() {
   if (this.multipleSelection.length != 0) {
    // 把要删除的用户ID以字符串拼接
    let number = "";
    for (let i = 0; i < this.multipleSelection.length; i++) {
     const element = this.multipleSelection[i];
     number += element.id + ",";
    }
    number = number.slice(0, number.length - 1); //去掉最后的逗号
    this.$confirm("此操作将永久删除所有选择项, 是否继续?", "提示", {
     confirmButtonText: "确定",
     cancelButtonText: "取消",
     type: "warning",
    })
     .then(() => {
      
     this.deleteReq(number)
     })
     .catch(() => {
      this.loading = false;
      this.$message({
       type: "info",
       message: "已取消删除",
      });
     });
   } else {
    this.$message.warning("请先选择要删除的数据");
   }
  },
  deleteReq(ids){
    this.loading = true;
     delFloor(ids).then((res) => {
       this.loading = false;
       if (res.data.code == "000000") {
        this.$message.success(res.data.message);
        this.getList();
        // this.reset()
       } else {
        this.$message.error(res.data.message);
       }
      });
  },

  handleSelectionChange(val) {
   this.multipleSelection = val;
   // console.log(val)
  },

  // 编辑楼层
  handleEdit(row) {
   console.log(row);
   ['id','buildingId','description','floorName','floorType','elevatorSort'].forEach(key=>{
     this.editData[key] = row[key];
   })
   this.dialogFormVisible2 = true;
  },
  // 编辑楼层确认
  confirmEditD(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     editFloor(this.editData).then((res) => {
      this.loading = false;
      if (res.data.code == "000000") {
       this.$message.success(res.data.message);
       this.getList();
       this.dialogFormVisible2 = false;
      } else {
       this.$message.error(res.data.message);
      }
     });
    } else {
     return false;
    }
   });
  },
  // 查询按钮
  handleSearch() {
   this.seekData.pageNum = 1;
   this.getList();
  },
  // 重置按钮
  reset() {
   this.seekData.brandId = null;
   this.seekData.hotelId = null;
   this.seekData.buildingId = null;
   this.seekData.floorName = null;
   this.seekData.pageNum = 1;
   this.getList();
  },
  //分页
  handleCurrentChange(cpage) {
   this.seekData.pageNum = cpage;
   this.getList();
  },
  handleSizeChange(psize) {
   this.seekData.pageSize = psize;
   this.seekData.pageNum = 1;
   this.getList();
  },
 },
};
</script>
<style lang="scss" scoped>
.astrict {
 .el-select {
  width: 100%;
 }
}
.my-box {
 .my-iemt {
  span {
   font-size: 14px;
  }
  white-space: nowrap;
 }
 .my-input {
  max-width: 200px;
 }
 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }
 .el-col {
  border-radius: 4px;
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;
  .el-input__inner {
   max-width: 190px;
  }
  span {
   font-size: 14px;
  }
 }
}
</style>
